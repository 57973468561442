<template>
  <div class="content">
    <navbar @asideFlagShow="asideFlagShow"></navbar>
    <div class="main">
      <div class="common-node" style="margin-bottom: 20px;">
        <div class="title">{{$t('rights.ordinary')}}</div>
        <div class="item">P1:{{$t('rights.income5')}}</div>
        <div class="item">P2:{{$t('rights.income10')}}</div>
        <div class="item">P3:{{$t('rights.income15')}}</div>
        <div class="item">P4:{{$t('rights.income20')}}</div>
        <div class="item">P5:{{$t('rights.income100')}}</div>
        <div class="item">P6:{{$t('rights.income500')}}</div>
      </div>
      <div class="global-node">
        <div class="title">{{$t("rights.qordinary")}}</div>
        <div class="item">1、{{$t("rights.distribution")}}</div>
        <div class="item">2、{{$t("rights.qordinary3")}}</div>
        <div class="item">3、{{$t("rights.qordinary30")}}</div>
    </div>
    </div>
    <asidePopup
      @asideFlagShow="asideFlagShow"
      style="text-align: left"
      :asideFlag="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },

  data() {
    return {
      asideFlag: false
    };
  },
  methods: {
    asideFlagShow(e) {
      this.asideFlag = e;
    },

  },
  created(){
    this.$store.dispatch("getSetting"); //网站配置

  }

};
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
}
.main {
  padding: 20px 22px 0 9px;
}
.common-node,.global-node {
  width: 100%;
  background: #f3f9ff;
  border-radius: 10px;
  padding: 15px 16px;
  .title{
    font-size: 15px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;margin-bottom: 10px;
  }
  .item{
    font-size: 12px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #3A8DFF;
line-height: 24px;
  }
}
</style>